// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "jquery";
import "bootstrap";
import "popper.js";

import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { searchBox, hits, infiniteHits } from "instantsearch.js/es/widgets";

window.$ = $;

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

$(document).on("turbolinks:load", () => {
  if (window.ga) {
    window.ga("set", "title", document.title);
    window.ga(
      "set",
      "page",
      document.location.pathname + document.location.search
    );
    window.ga("send", "pageview");
  }

  $(".carousel").carousel({
    interval: false,
  });

  $(".js-wishlist-info-trigger").on("click", (event) => {
    event.preventDefault();
    $(".js-wishlist-info").slideDown();
  });

  if ($("#searchbox").length > 0) {
    const searchClient = algoliasearch(
      window.algoliaAppId,
      "bf91856aba57aa6b52ff92d022766624"
    );

    const indexName = window.indexName;

    const searchParam = getParameterByName("q");

    const search = instantsearch({
      indexName: indexName,
      routing: {
        stateMapping: {
          stateToRoute(uiState) {
            const indexUiState = uiState[indexName];
            return {
              q: indexUiState.query,
              page: indexUiState.page,
              turbolinks: true,
            };
          },
          routeToState(routeState) {
            return {
              [indexName]: {
                query: routeState.q,
                page: routeState.page,
              },
            };
          },
        },
      },
      searchClient,
      initialUiState: {
        [indexName]: {
          query: searchParam,
        },
      },
    });

    search.addWidgets([
      searchBox({
        container: "#searchbox",
        autofocus: true,
        placeholder: "Hier tippen...",
      }),

      infiniteHits({
        container: "#hits",
        cssClasses: "yolo",
        templates: {
          showMoreText: "Mehr Ergebnisse anzeigen",
          empty:
            "<div style='padding: 24px;'>Für dein Suchbegriff <q>{{ query }}</q> können wir leider keine passenden Treffer finden.<div>",
          item: "<div style='position:relative;'><div style='position: relative; padding-top: 75%; background: #eee; height: 0; margin-bottom: 8px;'><img style='position: absolute; top: 0; left: 0;' loading='lazy' alt='{{{image_title}}}' class='img-fluid shadow-sm' src='{{{image_url}}}'/></div><strong>{{{_highlightResult.base_type.value}}} {{{_highlightResult.article_code_name.value}}} {{{_highlightResult.wood_name.value}}}</strong><br>{{{_highlightResult.surface_name.value}}} {{{_highlightResult.carving_name.value}}}<a href='{{{page_path}}}' class='stretched-link'></a></div>",
        },
      }),
    ]);
    search.start();
  }
});
